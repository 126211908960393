import { useState, useEffect, useMemo } from "react";

import { readTensorData } from "./safetensors";


interface ComputedStats {
    avg: number;
    sum: number;
    min: number;
    max: number;
}

export const FloatArray = (props: any) => {
    const { tensor } = props;
    const file = props.file as File;

    const [values, setValues] = useState<Float32Array | null>(null);
    const [offset, setOffset] = useState(0);
    const [pageSize, setPageSize] = useState(145);
    const [stats, setStats] = useState<ComputedStats | null>(null);

    useEffect(() => {
        if (tensor) {
            readTensorData(file, tensor, 0, 32).then(setValues);
        }

        setStats(null);
    }, [file, tensor]);

    const selection = useMemo(() => {
        return values ? values.subarray(offset, offset + pageSize) : null;
    }, [values, offset, pageSize]);

    const lengthStr = useMemo(() => `${values?.length ?? 0}`, [values]);
    const offsetPadded = useMemo(() => `${offset}`.padStart(lengthStr.length), [offset, lengthStr]);

    const onScroll = ev => {
        if (values) {
            const delta = (Math.max(1, Math.abs(ev.deltaY / 5)) * Math.sign(ev.deltaY)) | 0;
            setOffset(off => Math.max(0, Math.min((off + delta) | 0, values.length)) | 0);
        }
        ev.stopPropagation();
    };

    const computeAverage = () => {
        let accum = 0;
        let sum = 0;
        let min = +Infinity;
        let max = -Infinity;

        const count = values!.length;

        for (const f of values!) {
            accum += f / count;
            sum += f;

            if (f < min) {
                min = f;
            }

            if (f > max) {
                max = f;
            }
        }

        setStats({ avg: accum, sum, min, max });
    };

    return (<div className="float-data-wrapper">
        <div className="float-nav">
            <span>Offset: [-] {offsetPadded} / {lengthStr} [+]</span>

            <span className="float-nav-actions">
                {stats ? <span>Min: {stats.min.toFixed(4)} max: {stats.max.toFixed(4)} mean: {stats.avg.toFixed(4)} sum: {stats.sum.toFixed(4)}</span> : <button className="download-label" onClick={computeAverage}>Compute stats</button>}
            </span>
        </div>

        <div className="float-grid" onWheel={onScroll}>
            {selection ? Array.from(selection).map((f: any, i: number) => <div className="float-value" key={i}><div>{f.toFixed(10)}</div></div>) : null}
        </div>
    </div>);
};
