import { useState } from "react";

export const DropZone = (props: any) => {
    const { onFilesSelected, children, className, ...rest } = props;

    const [isDragging, setIsDragging] = useState(false);

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(true);
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) return;
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);
    };

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (files.length === 0) {
            return;
        }

        onFilesSelected(files);

        setIsDragging(false);
        return false;
    };

    return (
        <div className={"dropzone " + className} {...{ onDragEnter, onDragLeave, onDrop }} onDragOver={e => e.preventDefault()}>
            {isDragging && <div className="drag-callout-wrapper">
                <div className="drag-centered">
                    <div className="dropzone-callout">
                        <p className="text-center">Drop it like it's hot!</p>
                    </div>
                </div>
            </div>}

            {children}
        </div>
    );
};
