import { useMemo } from "react";
import { FloatArray } from "./FloatArray";

export const FloatExplorer = (props: any) => {
    const { selected = null, metadata } = props;
    const file = props.file as File;

    const tensor = useMemo(() => {
        if (selected) {
            return metadata.metadata[selected];
        }

        return null;
    }, [selected]);

    return (
        <div className="value-viewer dropzone">
            {selected ? <FloatArray tensor={tensor} file={file} />
                : <span className="float-placeholder">Select a tensor from the navigator to see its contents.</span>}
        </div>
    );
};
