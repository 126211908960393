import { DataExplorerFolder } from "./DataExplorerFolder";
import { DataExplorerValue } from "./DataExplorerValue";


export interface DataExplorerNodeProps {
    data: Record<string, any>,
    depth: number,
    download: any,
    selectTensor: any,
    selectedTensor: any;
}

export const DataExplorerNode = (props: DataExplorerNodeProps) => {
    const { data, depth, download, selectTensor, selectedTensor } = props;

    return (<ul className="data-explorer-node">
        {Object.keys(data).map(key => {
            const value = data[key];
            const args = { name: key, depth, download, selectTensor, selectedTensor };

            if (value.type === 'folder') {
                return <DataExplorerFolder key={key} data={value} {...args} />;
            } else if (value.type === 'value') {
                return <DataExplorerValue key={key} obj={value} {...args} />;
            }
        })}
    </ul>);
};
