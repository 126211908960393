import { useState } from 'react';
import { DataExplorer } from "./DataExplorer";
import { downloadBlobSlice, playWithFile } from "./safetensors";

import './app.scss';
import { DropZone } from "./DropZone";
import { FloatExplorer } from "./FloatExplorer";

function App() {
    const [metadata, setMetadata] = useState<any>(null);
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<any>(null);
    const [selectedTensor, setSelectedTensor] = useState<string | null>(null);

    const onFilesSelected = (files: File[]) => {
        setFile(files[0]);

        playWithFile(files[0]).then(v => {
            setError(null);
            setMetadata(v);
        }).catch(e => {
            console.error(e);
            setError(e);
        });
    };

    const download = (range: any, filename: string) => {
        downloadBlobSlice(file! as File, range[0], range[1], filename);
    };

    const selectTensor = (obj: any) => {
        setSelectedTensor(obj?.path ?? null);
    };

    return (
        <div className="viewer-root">
            <div className={"view-wrapper" + (file ? " has-file" : "")}>
                {(file && metadata) ? <FloatExplorer selected={selectedTensor} metadata={metadata} file={file} /> : null}

                <DropZone onFilesSelected={onFilesSelected} className={(file && metadata) ? "has-file" : ""}>
                    <div>
                        {!file && <div className="dropzone-info">
                            <p>Drop a <b>.safetensors</b> file here.</p>
                            <p className="footnote-quiet">The file won't be uploaded, nor leave this page.</p>
                        </div>}

                        {file && metadata && <div className="main-text-container">
                            <DataExplorer data={metadata.metadata} filename={file.name} download={download} selectTensor={selectTensor} selectedTensor={selectedTensor} />
                        </div>}

                        {file && error && <div className="parsing-error">
                            <p><b>Error</b>: {error.message}</p>
                        </div>}
                    </div>
                </DropZone>
            </div>

            <div className="footer-note">an application by Bitgate</div>
        </div>
    );
}


export default App;
