
function debugBuffer(x: Uint8Array) {
    // Ascii print
    const ascii = String.fromCharCode(...x.map((v) => v & 0x7f).map((v) => (v | 0) < 32 ? '?'.charCodeAt(0) : v));
    console.log(ascii);

    // Hex editor print, like xxd
    let hexDigits: any[] = [];
    let stringRepresenation: any[] = [];

    for (let i = 0; i < x.length; i++) {
        const hex = x[i].toString(16).padStart(2, '0');
        hexDigits.push(hex);

        const char = x[i] < 32 ? '.' : String.fromCharCode(x[i]);
        stringRepresenation.push(char);
    }

    console.log('');
    console.log('          00 01 02 03 04 05 06 07 08 09 0a 0b 0c 0d 0e 0f   0123456789abcdef');
    for (let i = 0; i < hexDigits.length; i += 16) {
        const addr = i.toString(16).padStart(8, '0');
        console.log(addr + "  " + hexDigits.slice(i, i + 16).join(' '), ' ', stringRepresenation.slice(i, i + 16).join(''));
    }
    console.log('');
}

export const DTYPE_TO_BYTES = {
    'F32': 4,
    'F64': 8,
    'I32': 4,
    'I64': 8,
    'I8': 1,
    'U8': 1,
    'U16': 2,
    'U32': 4,
    'U64': 8,
    'Bool': 1,
    'BF16': 2,
    'F16': 2,
};

export function sizeOf(value: any) {
    const numElements = value.shape ? value.shape.reduce((acc: number, v: number) => acc * v, 1) : 0;
    const byteSize = numElements * (DTYPE_TO_BYTES[value.dtype] ?? 0);

    return byteSize;
}

export function formatSize(size: number) {
    let unit = 'bytes';

    if (size > 1024) {
        size /= 1024;
        unit = 'KB';
    }

    if (size > 1024) {
        size /= 1024;
        unit = 'MB';
    }

    if (size > 1024) {
        size /= 1024;
        unit = 'GB';
    }

    size = Math.round(size * 100) / 100;

    return { unit, size };
}


export function computeTensorTree(data: any): any {
    let tree = {};

    Object.keys(data).forEach(key => {
        // Keys are separated by dots. We restore the tree structure.
        const value = data[key]; // Ex.: add_embedding.linear_1.bias

        const parts = key.split('.');

        let current: any = tree;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            const fullPath = parts.slice(0, i + 1).join('.');

            // Check if this is the last part
            if (i === parts.length - 1) {
                // Last part, assign the value
                const size = sizeOf(value);
                current[part] = { type: 'value', value, totalSize: size, path: fullPath };

                // Add the size of the value to the parent folder, walking up the tree
                let parent: any = tree;
                for (let i2 = 0; i2 < parts.length; i2++) {
                    const part = parts[i2];
                    parent.totalSize += size;

                    parent = parent[part];
                }
            } else {
                // Ensure the path exists
                current[part] ??= { type: 'folder', totalSize: 0, path: fullPath };

                // Move to the next level
                current = current[part];
            }
        }
    });

    return tree;
}


export async function playWithFile(file: File): Promise<any> {
    if (file.size < 8) {
        throw new Error('File is too small; safetensors header needs at least 8 bytes.');
    }

    const buffer = await file.slice(0, 8).arrayBuffer();

    // Read 8-byte unsigned long
    const view = new DataView(buffer);
    const size = view.getUint32(0, true);

    let meta: ArrayBuffer;

    try {
        meta = await file.slice(8, size + 8).arrayBuffer();
    } catch (e: any) {
        throw new Error('Failed to read metadata: ' + e.message);
    }

    if (meta.byteLength !== size) {
        throw new Error(`Metadata size mismatch: expected ${size} bytes, got ${meta.byteLength} bytes.`);
    }

    try {
        const metaString = new TextDecoder().decode(meta);
        const metadata: Record<string, any> = JSON.parse(metaString);

        for (const v of Object.values(metadata)) {
            if (v.hasOwnProperty("data_offsets")) {
                v.data_offsets = v.data_offsets.map(off => off + size);
            }
        }

        return { metadata, size };
    } catch (e: any) {
        throw new Error('Failed to parse metadata JSON: ' + e.message);
    }
}

export async function readTensorData(file: File, tensor: any, index: number, max: number = -1) {
    // console.log(tensor, file);
    const startTime = performance.now();
    const [start, end] = tensor.data_offsets;
    const bytes = await file.slice(start + (index * 4), end).arrayBuffer();
    const result = new Float32Array(bytes);
    console.log(performance.now() - startTime);
    return result;
}

export function downloadBlobSlice(file: File, start, end, filename) {
    const blob = file.slice(start, end);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function downloadBlob(blob: Blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function exportJson(json, filename: string) {
    const text = JSON.stringify(json, null, 4);
    const blob = new Blob([text]);
    downloadBlob(blob, filename);
}
