import { useState } from "react";
import { DataExplorerNode } from "./DataExplorerNode";
import { formatSize } from "./safetensors";


export interface DataExplorerFolderProps {
    name: string;
    depth: number;
    data: any;
    download: any;
    selectTensor: any;
    selectedTensor: any;
}

export const DataExplorerFolder = (props: DataExplorerFolderProps) => {
    const { name, depth, data, download, selectTensor, selectedTensor } = props;

    const [expanded, setExpanded] = useState(false);

    const { unit, size } = formatSize(data.totalSize);

    return (
        <li>
            <div className="data-explorer-folder">
                <button onClick={() => setExpanded(v => !v)} className="btn-folder-expand">
                    <div className="btn-folder-expand-text">
                        <span>{expanded ? '-' : '+'}</span>
                    </div>
                </button>

                <span className={"data-explorer-filename"} onClick={() => setExpanded(v => !v)}>{name}</span>
                <span className='text-thin'> ({size} {unit})</span>
            </div>

            {expanded && <DataExplorerNode data={data} depth={depth + 1} download={download} selectTensor={selectTensor} selectedTensor={selectedTensor} />}
        </li>
    );
};
