import { formatSize } from "./safetensors";


export interface DataExplorerValueProps {
    name: string;
    depth: number;
    obj: any;
    download: any;
    selectTensor: any;
    selectedTensor: any;
}


export const DataExplorerValue = (props: DataExplorerValueProps) => {
    const { name, depth, obj, download, selectTensor, selectedTensor } = props;

    if (!obj.value || !obj.value.shape || !obj.value.dtype) {
        return null;
    }

    const selected = selectedTensor === obj.path;

    const value = obj.value;
    const numElements = value.shape ? value.shape.reduce((acc: number, v: number) => acc * v, 1) : 0;

    let { unit, size } = formatSize(obj.totalSize);

    const shapeForFilename = value.shape ? ('--' + value.shape.join('x')) : '';
    const filename = `${obj.path}${shapeForFilename}.${value.dtype.toLowerCase()}.bin`;

    return (
        <li>
            <div className={`tree-label-wrapper ${selected && 'selected'}`} onClick={() => selectTensor(obj)}>
                <span className="text-thin-mono"> - {name}:</span>
                <span className="text-mono">{value.dtype} {'{'}{value.shape && value.shape.join(',')}{'}'}</span>
                <span className='text-thin'> ({size} {unit}, {numElements} elem.)</span>

                {value.data_offsets && <button className='download-label' title={filename} onClick={() => download(value.data_offsets, filename)}> extract</button>}
            </div>
        </li>
    );
};
