import { useMemo } from "react";
import { DataExplorerNode } from "./DataExplorerNode";
import { computeTensorTree, exportJson } from "./safetensors";

export interface DataExplorerProps {
    data: Record<string, any>;
    filename: string;
    download: any;
    selectTensor: any;
    selectedTensor: any;
}

export const DataExplorer = (props: DataExplorerProps) => {
    const { data, filename, download, selectTensor, selectedTensor } = props;

    const tree = useMemo(() => computeTensorTree(data), [data]);

    const downloadJson = () => exportJson(data, filename.replace(".safetensors", "") + ".metadata.json");
    const downloadButton = <button className="download-label" onClick={downloadJson} title="Downloads the parameter metadata as .json, unedited">export .json tree</button>;
    const numParams = Object.keys(data).length;

    return (<>
        <p className="file-title">
            {filename} ({numParams} parameters) {downloadButton}
        </p>

        <div className="data-explorer-wrapper">
            <DataExplorerNode
                data={tree}
                depth={0}
                download={download}
                selectTensor={selectTensor}
                selectedTensor={selectedTensor}
            />
        </div>
    </>
    );
};
